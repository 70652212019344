<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.add") }}
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="save"
        class="mr-2"
        :disabled="items.length < 1"
      >
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <SelectSuggestGoodsNoWarehouse
            @onSelect="selectItem"
            :select-items="items"
            :for-production="true"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="c-input-small"
            v-model="note"
            outlined
            dense
            hide-details
            :label="$t('labels.note')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-simple-table
            height="calc(100vh - 400px)"
            class="table-padding-2-no-top"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center">{{ $t("labels.sku") }}</th>
                  <th class="text-center">{{ $t("labels.barcode") }}</th>
                  <th class="text-center">{{ $t("labels.image") }}</th>
                  <th class="text-center">{{ $t("labels.machine_file") }}</th>
                  <th class="text-center">{{ $t("labels.category") }}</th>
                  <th class="text-center">{{ $t("labels.name") }}</th>
                  <th class="text-center">
                    {{ $t("labels.goods_description") }}
                  </th>
                  <th class="text-center">{{ $t("labels.size") }}</th>
                  <th class="text-center" style="width: 100px">Y.C Sản xuất</th>
                  <th class="text-center" style="width: 75px">
                    <v-btn color="error" x-small @click="clearAll()">
                      {{ $t("labels.clear") }}
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="result-item text-center"
                  v-for="item in items"
                  :key="`g_${item.id}`"
                >
                  <td>{{ item.sku }}</td>
                  <td>{{ item.customer_goods_barcode }}</td>
                  <td>
                    <ImageViewer
                      :url="
                        item.url_images ||
                        require('@/assets/common/no-image.jpg')
                      "
                      width="40px"
                      height="40px"
                    />
                  </td>
                  <td></td>
                  <td>{{ item.category_name }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.size }}</td>
                  <td>
                    <v-text-field
                      class="c-input-small"
                      v-model.number="item.quantity"
                      outlined
                      dense
                      hide-details
                      single-line
                      type="number"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-btn
                      x-small
                      color="error"
                      @click="removeItem(item)"
                      class="mb-1"
                    >
                      {{ $t("labels.delete") }}
                    </v-btn>
                    <br />
                    <v-btn
                      x-small
                      color="warning"
                      @click="showDialogReport(item)"
                    >
                      Tốc độ vơi
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="reportDialog" max-width="800px">
      <ReportForecast
        v-if="reportDialog"
        :sku="reportItem.sku"
        @close="hideDialogReport"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "RequirementCreate",
  components: {
    SelectSuggestGoodsNoWarehouse: () =>
      import("@/components/common/SelectSuggestGoodsNoWarehouse"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
    ReportForecast: () => import("@/components/goods/ReportForecast"),
  },
  props: {},
  data: () => ({
    files: null,
    isLoading: false,
    reportDialog: false,
    reportItem: {},
    items: [],
    note: null,
  }),
  computed: {},
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit("close", true);
    },
    showDialogReport(item) {
      this.reportItem = { ...item };
      this.reportDialog = true;
    },
    hideDialogReport() {
      this.reportItem = {};
      this.reportDialog = false;
    },
    selectItem(item) {
      this.items.push(item);
    },
    removeItem(item) {
      const items = [...this.items].filter((it) => it.id !== item.id);
      this.items = [...items];
    },
    clearAll() {
      const items = [];
      this.items = [...items];
    },
    async save() {
      try {
        const { data } = await httpClient.post(
          "/goods-production-requirement-break",
          {
            items: this.items,
            note: this.note,
          }
        );
        if (data) {
          const keys = Object.keys(data);
          if (
            keys.length > 1 &&
            !confirm(
              this.$t("messages.goods_production_requirement_create_confirm", {
                count: keys.length,
              })
            )
          ) {
            return false;
          }
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-create", {
          items: this.items,
          note: this.note,
        });
        this.isLoading = false;
        const msg = this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.$emit("refreshData", true);
        this.close();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
